import React, { useRef } from "react";
import { Container, HeadSplit, Body, Footer } from "../Overlay/column";
import { SettingsProps } from "../Login";
import { useHotkeys } from "react-hotkeys-hook";
import { useKvDataContext } from "../Context/kvServer";
import ShortcutHelp from "../ShortcutHelp/";

import { InputField } from "./InputField";
import { systemMessage } from "./systemMessage";

// Settings is a component for displaying and updating key-value data
export const Settings: React.FC<SettingsProps> = ({ handleClose }) => {
  const domain = "editor"; // Replace with your domain
  const firstFocusRef = useRef<HTMLInputElement>(null);
  const { logout } = useKvDataContext();

  useHotkeys(
    "esc",
    () => {
      handleClose();
    },
    {
      enableOnFormTags: true,
    },
  );

  const handleLogout = () => {
    logout();
    handleClose();
  };

  useHotkeys(
    "meta+l",
    () => {
      handleClose();
    },
    {
      enableOnFormTags: true,
    },
  );

  // Callback to set focus after loading completes
  const handleInputLoad = () => {
    if (firstFocusRef.current) {
      firstFocusRef.current.focus();
    }
  };

  return (
    <Container>
      <HeadSplit>
        <h2>Settings</h2>
        <button onClick={handleClose}>
          ❌
          <ShortcutHelp command="esc" />
        </button>
      </HeadSplit>
      <Body>
        <InputField
          ref={firstFocusRef}
          label="OpenAI API Key"
          id="assistant.ai.api.openai.key"
          domain={domain}
          onLoad={handleInputLoad}
          variant="textfield"
          spellcheck={false}
          autocomplete="off"
          autocorrect="off"
          autocapitalize="off"
        />
        <InputField
          label="OpenAI Model"
          id="assistant.ai.api.openai.model"
          initialValue="gpt-4o-mini"
          domain={domain}
          spellcheck={false}
          autocomplete="off"
          autocorrect="off"
          autocapitalize="off"
        />
        <InputField
          label="System Message"
          id="assistant.ai.api.openai.systemMessage"
          initialValue={systemMessage}
          domain={domain}
          variant="textfield"
        />
      </Body>
      <Footer>
        <button className="button" onClick={handleLogout}>
          Logout
          <ShortcutHelp command="meta+l" />
        </button>
      </Footer>
    </Container>
  );
};
