import "./style.css";

import React, { useState, useEffect, useRef, useCallback } from "react";

import { DiffComponent } from "../Diff/jsdiff";
import ShortcutHelp from "../ShortcutHelp/";
import { useHotkeys } from "react-hotkeys-hook";
import Select from "react-select";
import { useDomainData } from "../Context/kv/useDomainData";

import { prompts } from "../../utils/prompts.js";

import { Container, Head, HeadSplit, Body, Footer } from "../Overlay/column";
import { systemMessage } from "../Settings/systemMessage";

const domain = "editor";
const key = "assistant.ai.api.openai.key";
const model = "assistant.ai.api.openai.model";
const systemMessageKey = "assistant.ai.api.openai.systemMessage";

const Overlay = ({ selectedText, onClose }) => {
  const { value, error } = useDomainData(domain, key);
  const { value: modelValue, error: modelError } = useDomainData(domain, model);
  const { value: systemMessageValue, error: systemMessageError } =
    useDomainData(domain, systemMessageKey);
  const textareaRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");

  const [editText, setEditText] = useState("");
  const [, setApiResponse] = useState("");

  // Extracted fetchData function
  const fetchData = useCallback(async () => {
    const prompt = selectedValue;

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${value}`,
      },
      body: JSON.stringify({
        model: modelValue || "gpt-4o-mini",
        messages: [
          {
            role: "system",
            content: `${systemMessageValue || systemMessage}`,
          },
          { role: "user", content: prompt.trim() },
        ],
      }),
    };

    setLoading(true);
    const response = await fetch(
      "https://api.openai.com/v1/chat/completions",
      requestOptions,
    ).catch((error) => console.error(error));

    const data = await response.json();
    setLoading(false);

    let answer = data.choices[0].message.content;

    setApiResponse(answer);
    setEditText(answer);
  }, [selectedValue, value, modelValue, systemMessageValue]);

  useEffect(() => {
    if (selectedValue) {
      fetchData();
    }
  }, [selectedValue, fetchData]);

  const handleConfirm = () => {
    onClose(editText);
  };

  const handleClose = () => {
    onClose(null);
  };

  const handleTryAgain = () => {
    if (selectedValue) {
      fetchData();
    }
  };

  useHotkeys("esc", handleClose, {
    enableOnFormTags: true,
  });
  useHotkeys("meta+enter", handleConfirm, {
    enableOnFormTags: true,
  });

  const handleDropdownChange = (value) => {
    setSelectedValue(value.text.replaceAll("{{selection.text}}", selectedText));
  };

  if (error) {
    return <p>API KEY Error: {error.message}</p>;
  }

  if (modelError) {
    return <p>Model Error: {modelError.message}</p>;
  }

  if (systemMessageError) {
    return <p>System Message Error: {systemMessageError.message}</p>;
  }

  return (
    <div className="overlay">
      <div className="overlay__catch"></div>
      <div className="overlay__inner">
        <Container>
          <HeadSplit>
            <h2>Assistant </h2>
            <button className="" onClick={handleClose}>
              ❌
              <ShortcutHelp command="esc" />
            </button>
          </HeadSplit>
          <Body>
            <div className="overlay__columns">
              <div className="overlay_column">
                <br />
                <div className="text_view">
                  {selectedText || "((text will be inserted))"}
                </div>
              </div>
              <div className="overlay_column">
                <Container>
                  <Head>
                    <div>
                      <Select
                        options={prompts}
                        autoFocus={true}
                        onChange={handleDropdownChange}
                        getOptionValue={(item) => item.id}
                      />
                    </div>
                  </Head>
                  <Body>
                    <div className="overlay_column__text">
                      {loading ? (
                        <>loading...</>
                      ) : (
                        <textarea
                          className="overlay__textarea text_view"
                          value={editText}
                          onChange={(e) => setEditText(e.target.value)}
                          placeholder="Edit response here"
                          ref={textareaRef}
                        />
                      )}
                    </div>
                  </Body>
                  <Footer>
                    <div className="overlay_column__buttons">
                      <button className="button" onClick={handleTryAgain}>
                        Try again
                      </button>
                      <button className="button" onClick={handleConfirm}>
                        Replace
                        <ShortcutHelp command="meta+enter" />
                      </button>
                      {/* <button className="button">
                        Insert as next paragraph
                      </button> */}
                    </div>
                  </Footer>
                </Container>
              </div>
              <div className="overlay_column">
                <br />
                <br />
                <div className="text_view">
                  {selectedText && editText && (
                    <DiffComponent
                      originalText={selectedText}
                      newText={editText}
                    />
                  )}
                </div>
              </div>
            </div>
          </Body>
          <Footer></Footer>
        </Container>
      </div>
    </div>
  );
};

export default Overlay;
