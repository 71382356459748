function template(string, data = {}) {
  let out = string;

  for (const [key, value] of Object.entries(data)) {
    out = out.replaceAll(`{{${key}}}`, value);
  }

  return out;
}

// Function to create the options array
function createOptions(optionsRaw, blocks) {
  const blocksMap = blocks.reduce((acc, block) => {
    acc[block.id] = block.text;
    return acc;
  }, {});

  const options = optionsRaw.map((option, index) => {
    const id = `option-${index}`; // Generating a simple unique id
    const text = template(option.text, blocksMap);
    return { id, label: option.label, text };
  });

  return options;
}

const blocks = [
  {
    id: "format.no-explanations",
    text: "Only return the new text. No further explanations",
  },
  {
    id: "format.keep-language",
    text: "Keep the original language of the text.",
  },
  {
    id: "format.text-follows",
    text: "\n\nHere the Text:\n\n\n",
  },
];

const optionsRaw = [
  {
    label: "AI – fix Grammer",
    text: `Please correct the following text gramattically. {{format.no-explanations}}. {{format.keep-language}}. {{format.text-follows}}{{selection.text}}`,
  },
  {
    label: "AI – Improve Text",
    text: `Please correct the following text gramattically and stylitically. Make it more flow and clearer. {{format.no-explanations}}. {{format.keep-language}}. {{format.text-follows}}{{selection.text}}`,
  },
  {
    label: "AI – 10 synonyms",
    text: `Please give me 10 synonyms for the given text. {{format.no-explanations}}. {{format.keep-language}}. {{format.text-follows}}{{selection.text}}`,
  },
  {
    label: "AI – 3 rephrases",
    text: `Please rephrase the following text to make it better and give me tree variations of it. {{format.no-explanations}}. {{format.keep-language}}. {{format.text-follows}}{{selection.text}}`,
  },
  {
    label: "AI – Translate to English",
    text: `Please translate the following text to english. {{format.no-explanations}}. {{format.text-follows}}{{selection.text}}`,
  },
  {
    label: "AI – Translate to German",
    text: `Please translate the following text to german. {{format.no-explanations}}. {{format.text-follows}}{{selection.text}}`,
  },
];

export const prompts = createOptions(optionsRaw, blocks);
