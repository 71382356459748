import { useState, useEffect } from "react";
import { useKvDataContext, instance } from "../kvServer";

export const useDomainData = (domain, key) => {
  const { data, updateData } = useKvDataContext();
  console.log("useDomainData", data);

  const [value, setValue] = useState(data[key]?.data.value);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      if (data[key] === undefined) {
        setLoading(true);
        try {
          const response = await instance.get(`/${domain}/data/${key}`);
          updateData(key, response.data);
          setValue(response.data?.data.value);
        } catch (error) {
          setError(error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchData();
  }, [key, domain, data, updateData, setLoading, setError]);

  const updateValue = async (newValue) => {
    // Optimistically update the value
    updateData(key, { data: { value: newValue } });
    setValue(newValue);
    setLoading(true);

    try {
      const response = await instance.post(`/${domain}/data/`, {
        key,
        value: newValue,
      });
      // Update the value again with the response from the server
      if (response.data && response.data) {
        updateData(key, { data: response.data });
        setValue(response.data.value);
      }
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  // const updateValue = async (newValue) => {
  //   setLoading(true);
  //   try {
  //     updateData(key, { data: { value: newValue } });
  //     await instance.post(`/${domain}/data/`, { key, value: newValue });
  //     setValue(newValue);
  //   } catch (error) {
  //     setError(error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  return { value, loading, error, updateValue };
};
