import React, { useState, useEffect, ChangeEvent, forwardRef } from "react";
import { useDomainData } from "../Context/kv/useDomainData";
import { ErrorType } from "../Login";

// Define the props for the InputField component
interface InputFieldProps {
  id: string; // The key for the key-value pair
  domain: string; // The domain for the key-value pair
  label: string; // The label for the input field
  initialValue?: string; // The initial value for the input field
  onLoad?: () => void; // Callback invoked when loading completes
  variant?: "input" | "textfield"; // New prop
  spellcheck?: boolean; // New prop
  autocomplete?: string; // New prop
  autocorrect?: string; // New prop
  autocapitalize?: string; // New prop
}

/**
 * InputField is a component for displaying and updating key-value data.
 * It accepts a key and a domain as props.
 */
export const InputField = forwardRef<HTMLInputElement, InputFieldProps>(
  (
    {
      id,
      domain,
      label,
      initialValue,
      onLoad,
      variant = "input",
      spellcheck,
      autocomplete,
      autocorrect,
      autocapitalize,
    },
    ref,
  ) => {
    const { value, loading, error, updateValue } = useDomainData(domain, id);
    const [inputValue, setInputValue] = useState<string>("");

    useEffect(() => {
      if (value && !loading) {
        setInputValue(value);
      }
    }, [value, loading]);

    useEffect(() => {
      if (error && (error as ErrorType).response?.status === 404) {
        // Set value based on initialValue
        setInputValue(initialValue || "");
      }
    }, [error, initialValue]);

    // Notify parent when loading completes
    useEffect(() => {
      if (!loading && onLoad) {
        onLoad();
      }
    }, [loading, onLoad]);

    const handleInputChange = (
      e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
      setInputValue(e.target.value);
    };

    const handleUpdate = () => {
      updateValue(inputValue);
    };

    return (
      <div className="input-field-container">
        <label className="h3" htmlFor={id}>
          {label}
        </label>
        {variant === "textfield" ? (
          <textarea
            ref={ref as React.Ref<HTMLTextAreaElement>}
            id={id}
            value={inputValue}
            onChange={handleInputChange}
            disabled={loading}
            placeholder={loading ? "Loading..." : undefined}
            spellCheck={spellcheck}
            autoComplete={autocomplete}
            autoCorrect={autocorrect}
            autoCapitalize={autocapitalize}
          />
        ) : (
          <input
            ref={ref}
            type="text"
            id={id}
            value={inputValue}
            onChange={handleInputChange}
            disabled={loading}
            placeholder={loading ? "Loading..." : undefined}
            spellCheck={spellcheck}
            autoComplete={autocomplete}
            autoCorrect={autocorrect}
            autoCapitalize={autocapitalize}
          />
        )}

        <button className="button" onClick={handleUpdate} disabled={loading}>
          {loading ? "Updating..." : "Update Value"}
        </button>

        {error && (error as ErrorType).response?.status !== 404 && (
          <p>Error: {(error as ErrorType).message}</p>
        )}
      </div>
    );
  },
);

InputField.displayName = "InputField";
